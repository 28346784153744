import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Card.css";
import Icon from "../../../Icon";
import NavItemStatus from "../../../NavBar/NavItemStatus";

class Card extends Component {
  render() {
    return (
      <div className={this.props.card.cardClassName + " card"}>
        <div className="mx-auto">
          <Icon
            iconName={this.props.card.icon}
            color={this.props.card.iconColor}
            size={100}
          />
        </div>
        <p className="text-center contentTextSize">{this.props.card.text}</p>
        <Link
          state={{ sectionId: this.props.card.serviceSectionId }}
          to={this.props.card.linkTo}
          className={this.props.card.buttonClassName}
          onClick={() => NavItemStatus.SetActiveLink(this.props.card.linkId)}
        >
          <Icon
            iconName="arrow-right-circle"
            color={this.props.card.buttonColor}
            size={40}
          />
        </Link>
      </div>
    );
  }
}

export default Card;
