import React, { Component } from "react";

class Icon extends Component {
  render() {
    const className =
      "bi bi-" + this.props.iconName + " text-" + this.props.color;
    return <i className={className} style={{ fontSize: this.props.size }} />;
  }
}

export default Icon;
