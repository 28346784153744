import * as React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../Icon";

const NotFound = ({ dbSettings }) => {
  const navigate = useNavigate();

  const handleBtnClick = (path) => {
    navigate(path);
  };

  return (
    <div className={`text-${dbSettings.HeaderColor} m-3 row`}>
      <div className="mx-auto text-center">
        <Icon
          iconName={dbSettings.IconName}
          color={dbSettings.IconBtnColor}
          size="3em"
        />
      </div>
      <span className="headers">{dbSettings.HeaderContent}</span>
      <button
        style={{ maxWidth: "500px" }}
        onClick={() => handleBtnClick("/")}
        className={`mt-3 d-block btn btn-black border border-2 border-${dbSettings.IconBtnColor} text-${dbSettings.IconBtnColor} mx-auto`}
      >
        Home
      </button>
      <button
        style={{ maxWidth: "500px" }}
        onClick={() => handleBtnClick("/services")}
        className={`mt-3 d-block btn btn-black border border-2 border-${dbSettings.IconBtnColor} text-${dbSettings.IconBtnColor} mx-auto`}
      >
        Services
      </button>
      <button
        style={{ maxWidth: "500px" }}
        onClick={() => handleBtnClick("/about-me")}
        className={`mt-3 d-block btn btn-black border border-2 border-${dbSettings.IconBtnColor} text-${dbSettings.IconBtnColor} mx-auto`}
      >
        About Me
      </button>
      <button
        style={{ maxWidth: "500px" }}
        onClick={() => handleBtnClick("/contact")}
        className={`mt-3 d-block btn btn-black border border-2 border-${dbSettings.IconBtnColor} text-${dbSettings.IconBtnColor} mx-auto`}
      >
        Contact
      </button>
    </div>
  );
};

export default NotFound;
