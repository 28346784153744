import React, { useRef } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import Section from "./Section/Section";
import { useLocation } from "react-router-dom";
import Scroller from "../../Scroller";
import { useEffect } from "react";
import NavItemStatus from "../../NavBar/NavItemStatus";

const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 768, md: 900, lg: 1200, xl: 1536 } },
});

const GetSections = (data) => {
  let settings = data.Sections;

  return [
    {
      id: 0,
      header: settings[0].HeaderContent,
      headerForeground: settings[0].HeaderForeground,
      sectionBackground: settings[0].SectionBackground,
      bulletPoints: settings[0].BulletPoints,
      textForeground: settings[0].SectionForeground,
      icon: settings[0].IconName,
      borderColor: settings[0].BorderColor,
      iconColor: settings[0].IconColor,
    },
    {
      id: 1,
      header: settings[1].HeaderContent,
      headerForeground: settings[1].HeaderForeground,
      sectionBackground: settings[1].SectionBackground,
      bulletPoints: settings[1].BulletPoints,
      textForeground: settings[1].SectionForeground,
      icon: settings[1].IconName,
      borderColor: settings[1].BorderColor,
      iconColor: settings[1].IconColor,
    },
    {
      id: 2,
      header: settings[2].HeaderContent,
      headerForeground: settings[2].HeaderForeground,
      sectionBackground: settings[2].SectionBackground,
      bulletPoints: settings[2].BulletPoints,
      textForeground: settings[2].SectionForeground,
      icon: settings[2].IconName,
      borderColor: settings[2].BorderColor,
      iconColor: settings[2].IconColor,
    },
    {
      id: 3,
      header: settings[3].HeaderContent,
      headerForeground: settings[3].HeaderForeground,
      sectionBackground: settings[3].SectionBackground,
      bulletPoints: settings[3].BulletPoints,
      textForeground: settings[3].SectionForeground,
      icon: settings[3].IconName,
      borderColor: settings[3].BorderColor,
      iconColor: settings[3].IconColor,
    },
  ];
};

const Services = ({ dbSettings }) => {
  Scroller.ScrollToTop();

  const location = useLocation();
  let sectionId;
  if (location != null) sectionId = location.state;
  const myRef = useRef();

  const Sections = GetSections(dbSettings);

  let retval = (
    <div className="mx-auto">
      <MuiThemeProvider theme={theme}>
        {Sections.map((section) => (
          <div
            ref={
              sectionId != null
                ? sectionId.sectionId == section.id
                  ? myRef
                  : null
                : null
            }
            key={section.id}
          >
            <Section
              id={`section${section.id}`}
              section={section}
              alignIcon={section.id % 2 == 0 ? "right" : "left"}
            />
          </div>
        ))}
      </MuiThemeProvider>
    </div>
  );

  useEffect(() => {
    if (sectionId != null && sectionId.sectionId != -1) {
      window.scrollTo(0, myRef.current.offsetTop - 65);
    }

    NavItemStatus.SetActiveLink("servicesLink");
  });

  return retval;
};

export default Services;
