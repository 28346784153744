import "./Home.css";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect } from "react";
import AnimatedHeader from "./AnimatedHeader/AnimatedHeader";
import AnimatedCard from "./AnimatedCard/AnimatedCard";
import AnimatedSubCard from "./AnimatedSubCard/AnimatedSubCard";
import Scroller from "../../Scroller";
import NavItemStatus from "../../NavBar/NavItemStatus";

const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 768, md: 900, lg: 1200, xl: 1536 } },
});

const GetCards = (data) => {
  let settings = data.Cards;

  const cardStylings =
    "d-flex flex-column justify-content-between rounded border border-2 px-4 gridElement";

  const cardButtonStylings = "mx-auto mb-3";

  const retVal = [];
  settings.forEach((setting) => {
    retVal.push({
      id: setting.Id,
      icon: setting.IconName,
      iconColor: setting.ContentColor,
      text: setting.Text,
      cardClassName: `${cardStylings} bg-${setting.BackgroundColor} text-${setting.ContentColor} border-${setting.BorderColor}`,
      buttonClassName: `${cardButtonStylings} bg-${setting.BackgroundColor} text-${setting.ButtonForeground}`,
      buttonColor: setting.ButtonForeground,
      animationDelay: 750,
      serviceSectionId: setting.SectionId,
      linkTo: setting.linkTo,
      linkId: setting.linkId,
    });
  });

  return retVal;
};

const GetSubCards = (data) => {
  let settings = data.SubCards;

  const retVal = [];
  settings.forEach((setting) => {
    retVal.push({
      id: settings.indexOf(setting),
      text: setting.Content,
      link: setting.Link,
      textColor: setting.ContentColor,
      BorderColor: setting.BorderColor,
      linkId: setting.linkId,
    });
  });

  return retVal;
};

const Home = ({ dbSettings }) => {
  Scroller.ScrollToTop();

  const Cards = GetCards(dbSettings);
  const SubCards = GetSubCards(dbSettings);

  useEffect(() => {
    NavItemStatus.SetActiveLink("homeLink");
  });

  return (
    <div className="mx-auto content">
      <div>
        <AnimatedHeader />
        <div className="px-4">
          <MuiThemeProvider theme={theme}>
            <Grid container spacing={3} style={{ marginTop: "0.5em" }}>
              {Cards.map((card) => (
                <Grid key={card.id} item xs={12} sm={6} md={3}>
                  <AnimatedCard card={card} />
                </Grid>
              ))}
            </Grid>
          </MuiThemeProvider>
          <MuiThemeProvider theme={theme}>
            <Grid container spacing={3} className="mt-4">
              {SubCards.map((card) => (
                <Grid key={card.id} item xs={12} sm={4}>
                  <AnimatedSubCard subCard={card} />
                </Grid>
              ))}
            </Grid>
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default Home;
