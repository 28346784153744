import React from "react";
import { useSpring, animated, config } from "react-spring";
import "./AnimatedHeader.css";

const GetHeaderProps = (num) => {
  return useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: num == 1 ? 250 : num == 2 ? 750 : 1000,
    config: config.gentle,
  });
};

const AnimatedHeader = () => {
  const header1Props = GetHeaderProps(1);
  const header2Props = GetHeaderProps(2);
  const header3Props = GetHeaderProps(3);

  return (
    <div id="animatedHeader" className="headers text-light ps-4">
      <animated.span style={header1Props}>Revamp </animated.span>
      <animated.span style={header2Props}>Your </animated.span>
      <animated.span style={header3Props}>Business</animated.span>
    </div>
  );
};

export default AnimatedHeader;
