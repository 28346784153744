import React, { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./NavBar.css";
import NavItemStatus from "./NavItemStatus";

const NavBar = ({ dbSettings }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div id="navbar" className="bg-black sticky-top">
      <Navbar
        collapseOnSelect
        expand="md"
        bg="black"
        variant="dark"
        className="p-2 mx-auto"
        style={{ maxWidth: "1600px" }}
        expanded={expanded}
      >
        <Link
          to="/"
          onClick={() => {
            NavItemStatus.SetActiveLink("homeLink");
            setExpanded(false);
          }}
          className="mx-0"
        >
          <img
            src={dbSettings.NavBar.DesktopLogoUrl}
            id="desktopLogo"
            width="150px"
            className="mx-3"
            alt="Teller Development Logo"
          />
          <img
            src={dbSettings.NavBar.MobileLogoUrl}
            id="mobileLogo"
            width="200px"
            alt="Teller Development Logo"
          />
        </Link>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <div className="vr bg-white vrDiv" style={{ marginRight: "1em" }} />
          <Nav className="me-auto text-center customNav">
            <Link
              to="/"
              id="homeLink"
              className="navbar-brand nav-link px-0 navbarItem"
              onClick={() => {
                NavItemStatus.SetActiveLink("homeLink");
                setExpanded(false);
              }}
            >
              Home
            </Link>
            <Link
              to="/services"
              id="servicesLink"
              className="navbar-brand nav-link px-0 navbarItem"
              onClick={() => {
                NavItemStatus.SetActiveLink("servicesLink");
                setExpanded(false);
              }}
            >
              Services
            </Link>
            <Link
              to="/portfolio"
              id="portfolioLink"
              className="navbar-brand nav-link px-0 navbarItem"
              onClick={() => {
                NavItemStatus.SetActiveLink("portfolioLink");
                setExpanded(false);
              }}
            >
              Portfolio
            </Link>
            <Link
              to="/about-me"
              id="aboutMeLink"
              className="navbar-brand nav-link px-0 navbarItem"
              onClick={() => {
                NavItemStatus.SetActiveLink("aboutMeLink");
                setExpanded(false);
              }}
            >
              About Me
            </Link>
          </Nav>
          <Nav className="text-center">
            <Link
              to="/contact"
              id="contactBtn"
              className="navbar-brand text-info border-info"
              onClick={() => {
                NavItemStatus.SetActiveLink("contactBtn");
                setExpanded(false);
              }}
            >
              Contact
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <hr className="bg-light m-0" />
    </div>
  );
};

export default NavBar;
