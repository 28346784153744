import React, { useEffect } from "react";
import Scroller from "../../Scroller";
import NavItemStatus from "../../NavBar/NavItemStatus";
import { useSpring, animated, config } from "react-spring";

const Portfolio = ({ dbSettings }) => {
  Scroller.ScrollToTop();

  useEffect(() => {
    NavItemStatus.SetActiveLink("portfolioLink");
  });

  const GetAnimation = (delay) => {
    return useSpring({
      from: { opacity: 0, y: -50 },
      to: { opacity: 1, y: 0 },
      config: config.gentle,
      delay: delay,
    });
  };

  let delay = 250;

  return (
    <div className="mx-auto px-4 px-lg-5 py-3 content">
      {dbSettings.Clients.map((client) => (
        <div
          key={`client${dbSettings.Clients.indexOf(client)}`}
          className="row"
        >
          <animated.div
            style={GetAnimation((delay += 150))}
            className="headers text-center text-lg-start px-0 pb-3 text-white"
          >
            {client.Name}
          </animated.div>
          <animated.a
            className="rounded border border-info p-0 mb-3 col-12 col-lg-6"
            href={client.Url}
            target="_blank"
            style={{ display: "block", ...GetAnimation((delay += 150)) }}
          >
            <video
              style={{ height: "100%", width: "100%", display: "block" }}
              autoPlay
              loop
              muted
              playsInline
              src={client.VideoSrc}
              allowFullScreen={false}
              className="rounded"
            />
          </animated.a>
          <animated.div
            style={GetAnimation((delay += 150))}
            className="col-12 col-lg-6 text-white contentTextSize my-auto text-center"
          >
            {client.Description}
            <div>
              <a
                href={client.Url}
                target="_blank"
                className="text-info my-3 d-inline-block mx-auto btn rounded border border-2 border-info"
              >
                Visit Site
              </a>
            </div>
          </animated.div>
          <hr
            className={`d-${
              dbSettings.Clients.indexOf(client) ==
              dbSettings.Clients.length - 1
                ? "none"
                : "block"
            } border border-info border-1 opacity-100 mt-3 mt-lg-4`}
          />
        </div>
      ))}
    </div>
  );
};

export default Portfolio;
