import React from "react";
import { Link } from "react-router-dom";
import NavItemStatus from "../NavBar/NavItemStatus";

const Footer = ({ dbSettings }) => {
  return (
    <div className="bg-black px-3 pt-3">
      <div className="container" style={{ maxWidth: "1600px" }}>
        <footer>
          <ul className="nav justify-content-center border-bottom pb-2 mb-2">
            <li className="nav-item">
              <Link
                to="/"
                onClick={() =>
                  NavItemStatus.SetActiveLink(dbSettings.Footer.LinkIds[0])
                }
                className="nav-link px-2 text-muted"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/services"
                onClick={() =>
                  NavItemStatus.SetActiveLink(dbSettings.Footer.LinkIds[1])
                }
                className="nav-link px-2 text-muted"
              >
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/portfolio"
                onClick={() =>
                  NavItemStatus.SetActiveLink(dbSettings.Footer.LinkIds[2])
                }
                className="nav-link px-2 text-muted"
              >
                Portfolio
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/about-me"
                onClick={() =>
                  NavItemStatus.SetActiveLink(dbSettings.Footer.LinkIds[3])
                }
                className="nav-link px-2 text-muted"
              >
                About Me
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contact"
                onClick={() =>
                  NavItemStatus.SetActiveLink(dbSettings.Footer.LinkIds[4])
                }
                className="nav-link px-2 text-muted"
              >
                Contact
              </Link>
            </li>
          </ul>
          <p className="pb-2 text-center text-light">Teller Development</p>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
