import React from "react";
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import NavItemStatus from "../../../NavBar/NavItemStatus";
import "./AnimatedSubCard.css";

const GetSubCardAnimation = () => {
  return useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 500 },
    delay: 750,
  });
};

const AnimatedSubCard = ({ subCard }) => {
  return (
    <animated.div
      style={GetSubCardAnimation()}
      className={
        "gridElement border rounded border-2 p-3 text-center border-" +
        subCard.BorderColor
      }
    >
      <Link
        to={"/" + subCard.link}
        onClick={() => NavItemStatus.SetActiveLink(subCard.linkId)}
        className={`text-${subCard.textColor}`}
        style={{ textDecoration: "none" }}
      >
        <span className="contentTextSize my-auto">{subCard.text}</span>
      </Link>
    </animated.div>
  );
};

export default AnimatedSubCard;
