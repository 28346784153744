import { createTheme, Grid, MuiThemeProvider } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSpring, animated, config } from "react-spring";
import Icon from "../../Icon";
import NavItemStatus from "../../NavBar/NavItemStatus";
import Scroller from "../../Scroller";
import "./AboutMe.css";

const GetDesktopIcon = (textSection, index, color) => {
  return (
    <animated.div style={GetIconAnimation(index)} className="text-center">
      <Icon iconName={textSection.IconName} color={color} size={100} />
    </animated.div>
  );
};

const GetDesktopImage = (index, url, imgAlt) => {
  return (
    <animated.img
      alt={imgAlt}
      style={GetIconAnimation(index)}
      src={url}
      className="border border-2 border-info rounded img-fluid"
    />
  );
};

const GetMobileIcon = (textSection, index, color) => {
  return (
    <animated.div style={GetIconAnimation(index)} className="text-center">
      <Icon iconName={textSection.IconName} color={color} size={100} />
    </animated.div>
  );
};

const GetMobileImage = (index, url, imgAlt) => {
  return (
    <div className="px-5">
      <animated.img
        alt={imgAlt}
        style={GetIconAnimation(index)}
        src={url}
        className="border border-2 border-info rounded img-fluid"
      />
    </div>
  );
};

const GetIconAnimation = (num) => {
  return useSpring({
    from: { opacity: 0, x: -100 },
    to: { opacity: 1, x: 0 },
    config: config.gentle,
    delay: num == 0 ? 100 : num == 1 ? 350 : 600,
  });
};

const AboutMe = ({ dbSettings }) => {
  Scroller.ScrollToTop();

  const theme = createTheme({
    breakpoints: { values: { xs: 0, sm: 768, md: 900, lg: 1200, xl: 1536 } },
  });

  const textSections = dbSettings.TextSections;

  useEffect(() => {
    NavItemStatus.SetActiveLink("aboutMeLink");
  });

  return (
    <div id="aboutMeRoot" className="mx-auto pt-3 px-4 text-light">
      <MuiThemeProvider theme={theme}>
        {textSections.map((textSection) => (
          <div
            className="aboutMeSection"
            key={textSections.indexOf(textSection)}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={2} className="my-auto">
                {textSection.IconName != "" ? (
                  <div className="desktopIcon">
                    {GetDesktopIcon(
                      textSection,
                      textSections.indexOf(textSection),
                      dbSettings.IconColor
                    )}
                  </div>
                ) : (
                  <div className="desktopIcon">
                    {GetDesktopImage(
                      textSections.indexOf(textSection),
                      textSection.ImageUrl,
                      textSection.ImageAlt
                    )}
                  </div>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={10}
                className="my-auto aboutMeHeaderMobile"
              >
                <span>
                  <div className="aboutMeHeader d-inline-block">
                    {textSection.Header}
                    <hr
                      className={`border border-${dbSettings.UnderlineColor} border-1 opacity-100`}
                    />
                  </div>
                  {textSection.IconName != "" ? (
                    <div className="mobileIcon">
                      {GetMobileIcon(
                        textSection,
                        textSections.indexOf(textSection),
                        dbSettings.IconColor
                      )}
                    </div>
                  ) : (
                    <div className="mobileIcon">
                      {GetMobileImage(
                        textSections.indexOf(textSection),
                        textSection.ImageUrl,
                        textSection.ImageAlt
                      )}
                    </div>
                  )}
                  <div className="contentTextSize my-3">{textSection.Text}</div>
                </span>
              </Grid>
            </Grid>
            <div className="separator">
              <hr
                className={`border border-1 border-${
                  dbSettings.HrColor
                } opacity-100 d-${
                  textSections.indexOf(textSection) == textSections.length - 1
                    ? "none"
                    : "block"
                }`}
              />
            </div>
          </div>
        ))}
      </MuiThemeProvider>
    </div>
  );
};

export default AboutMe;
