import * as AWS from "aws-sdk";

export const GetDefaultData = () => {
  const data = {
    Id: {
      N: "0",
    },
    Footer: {
      M: {
        LinkIds: {
          L: [
            {
              S: "homeLink",
            },
            {
              S: "servicesLink",
            },
            {
              S: "aboutMeLink",
            },
            {
              S: "contactBtn",
            },
          ],
        },
      },
    },
    NavBar: {
      M: {
        DesktopLogoUrl: {
          S: "https://tellerdevelopmentimages.s3.us-west-1.amazonaws.com/ffe0fcc3-65c0-4cd4-826e-e50c2b039340",
        },
        MobileLogoUrl: {
          S: "https://tellerdevelopmentimages.s3.us-west-1.amazonaws.com/defaf3c4-3cfc-4a8b-a2cb-620247c3bd73",
        },
      },
    },
    Pages: {
      L: [
        {
          M: {
            Cards: {
              L: [
                {
                  M: {
                    Id: {
                      N: "0",
                    },
                    BackgroundColor: {
                      S: "black",
                    },
                    BorderColor: {
                      S: "info",
                    },
                    ButtonForeground: {
                      S: "light",
                    },
                    ContentColor: {
                      S: "light",
                    },
                    IconName: {
                      S: "code-square",
                    },
                    linkId: {
                      S: "servicesLink",
                    },
                    linkTo: {
                      S: "/services",
                    },
                    SectionId: {
                      S: "0",
                    },
                    Text: {
                      S: "Give customers a fresh look into your business with a custom-built website.",
                    },
                  },
                },
                {
                  M: {
                    Id: {
                      N: "1",
                    },
                    BackgroundColor: {
                      S: "black",
                    },
                    BorderColor: {
                      S: "info",
                    },
                    ButtonForeground: {
                      S: "light",
                    },
                    ContentColor: {
                      S: "light",
                    },
                    IconName: {
                      S: "cloud-check",
                    },
                    linkId: {
                      S: "servicesLink",
                    },
                    linkTo: {
                      S: "/services",
                    },
                    SectionId: {
                      S: "1",
                    },
                    Text: {
                      S: "Utilize cutting-edge cloud technologies for your website's infrastructure.",
                    },
                  },
                },
                {
                  M: {
                    Id: {
                      N: "2",
                    },
                    BackgroundColor: {
                      S: "black",
                    },
                    BorderColor: {
                      S: "info",
                    },
                    ButtonForeground: {
                      S: "light",
                    },
                    ContentColor: {
                      S: "light",
                    },
                    IconName: {
                      S: "sliders",
                    },
                    linkId: {
                      S: "servicesLink",
                    },
                    linkTo: {
                      S: "/services",
                    },
                    SectionId: {
                      S: "2",
                    },
                    Text: {
                      S: "Maintain flexibility and customization without the need for code changes.",
                    },
                  },
                },
                {
                  M: {
                    Id: {
                      N: "3",
                    },
                    BackgroundColor: {
                      S: "black",
                    },
                    BorderColor: {
                      S: "info",
                    },
                    ButtonForeground: {
                      S: "light",
                    },
                    ContentColor: {
                      S: "light",
                    },
                    IconName: {
                      S: "chat-right-dots",
                    },
                    linkId: {
                      S: "contactBtn",
                    },
                    linkTo: {
                      S: "/contact",
                    },
                    SectionId: {
                      S: "-1",
                    },
                    Text: {
                      S: "Work 1-on-1 with the developer to design the best possible website for your business.",
                    },
                  },
                },
              ],
            },
            Name: {
              S: "Home",
            },
            SubCards: {
              L: [
                {
                  M: {
                    BorderColor: {
                      S: "light",
                    },
                    Content: {
                      S: "Services I Offer",
                    },
                    ContentColor: {
                      S: "light",
                    },
                    Link: {
                      S: "services",
                    },
                    linkId: {
                      S: "servicesLink",
                    },
                  },
                },
                {
                  M: {
                    BorderColor: {
                      S: "light",
                    },
                    Content: {
                      S: "About Me",
                    },
                    ContentColor: {
                      S: "light",
                    },
                    Link: {
                      S: "about-me",
                    },
                    linkId: {
                      S: "aboutMeLink",
                    },
                  },
                },
                {
                  M: {
                    BorderColor: {
                      S: "light",
                    },
                    Content: {
                      S: "Get Started Today",
                    },
                    ContentColor: {
                      S: "light",
                    },
                    Link: {
                      S: "contact",
                    },
                    linkId: {
                      S: "contactBtn",
                    },
                  },
                },
              ],
            },
          },
        },
        {
          M: {
            Name: {
              S: "Services",
            },
            Sections: {
              L: [
                {
                  M: {
                    BorderColor: {
                      S: "info",
                    },
                    BulletPoints: {
                      L: [
                        {
                          S: "You didn't start your business from a template, so don't build your website from one. I work with you to design, develop, and maintain the most beautiful and professional-looking website for your business, targeted at your customers.",
                        },
                      ],
                    },
                    HeaderContent: {
                      S: "Custom Websites",
                    },
                    HeaderForeground: {
                      S: "light",
                    },
                    IconColor: {
                      S: "white",
                    },
                    IconName: {
                      S: "braces",
                    },
                    SectionBackground: {
                      S: "black",
                    },
                    SectionForeground: {
                      S: "light",
                    },
                  },
                },
                {
                  M: {
                    BorderColor: {
                      S: "info",
                    },
                    BulletPoints: {
                      L: [
                        {
                          S: "Utilize multiple services from one of the most popular cloud providers - AWS (Amazon Web Services) - to power your website's backend and keep business secure and running smoothly.",
                        },
                        {
                          S: "Cloud databases can power a robust Products page, a custom Events page, or a delicious-looking menu on your website.",
                        },
                      ],
                    },
                    HeaderContent: {
                      S: "Cloud Architecture",
                    },
                    HeaderForeground: {
                      S: "light",
                    },
                    IconColor: {
                      S: "white",
                    },
                    IconName: {
                      S: "cloud",
                    },
                    SectionBackground: {
                      S: "black",
                    },
                    SectionForeground: {
                      S: "light",
                    },
                  },
                },
                {
                  M: {
                    BorderColor: {
                      S: "info",
                    },
                    BulletPoints: {
                      L: [
                        {
                          S: "Should you have to pay developers to change the content on your website? Or to update your business hours? How about posting new events/specials?",
                        },
                        {
                          S: "Absolutely not. Not only will you get a custom-built website, but you'll also get custom tools to update various aspects of your website without the need for code changes.",
                        },
                      ],
                    },
                    HeaderContent: {
                      S: "Flexibility",
                    },
                    HeaderForeground: {
                      S: "light",
                    },
                    IconColor: {
                      S: "white",
                    },
                    IconName: {
                      S: "sliders2-vertical",
                    },
                    SectionBackground: {
                      S: "black",
                    },
                    SectionForeground: {
                      S: "light",
                    },
                  },
                },
              ],
            },
          },
        },
        {
          M: {
            Content: {
              S: "Fill out this form to ask questions, learn more, and get started transforming your website today.",
            },
            Foreground: {
              S: "light",
            },
            HrColor: {
              S: "info",
            },
            Icon: {
              S: "send",
            },
            IconColor: {
              S: "info",
            },
            Name: {
              S: "Contact",
            },
            SubmitButtonColor: {
              S: "info",
            },
            SubmitButtonText: {
              S: "Send",
            },
          },
        },
        {
          M: {
            HrColor: {
              S: "white",
            },
            IconColor: {
              S: "info",
            },
            Name: {
              S: "AboutMe",
            },
            TextSections: {
              L: [
                {
                  M: {
                    Header: {
                      S: "Experience",
                    },
                    IconName: {
                      S: "briefcase",
                    },
                    ImageAlt: {
                      S: "",
                    },
                    ImageUrl: {
                      S: "",
                    },
                    Text: {
                      S: "I've worked for several years as a software engineer, building and improving complex applications and systems. My experience has taken me into the financial and legal industries, designing, architecting, and developing software for different companies. I've grown as a developer by being exposed to a multitude of different programming technologies, such as mobile, web, and desktop development, backend and web API development, and database management. I strive to stay up-to-date with the latest advancements in software development, with a particular focus on web development and cloud computing.",
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "B.S. Computer Science, Dakota State University",
                    },
                    IconName: {
                      S: "mortarboard",
                    },
                    ImageAlt: {
                      S: "",
                    },
                    ImageUrl: {
                      S: "https://tellerdevelopmentimages.s3.us-west-1.amazonaws.com/e3acdccc-945c-47b1-be90-b95e510da889",
                    },
                    Text: {
                      S: "I earned my Bachelor's degree in Computer Science from Dakota State University in Fall 2020. During my studies, I quickly narrowed in on the world of software development and began to focus primarily on web and mobile development. But because of the broad scope of Computer Science, I left with a vast knowledge of technology, including networking, security, operating systems, language processing, and more.",
                    },
                  },
                },
                {
                  M: {
                    Header: {
                      S: "My Life",
                    },
                    IconName: {
                      S: "",
                    },
                    ImageAlt: {
                      S: "Dylan Teller and his dog Benji sitting together",
                    },
                    ImageUrl: {
                      S: "https://tellerdevelopmentimages.s3.us-west-1.amazonaws.com/37ee5128-4555-49e9-b512-abac0ba629ed",
                    },
                    Text: {
                      S: "Hi there! My name is Dylan Teller, and I'm a San Diego native, dog lover, and ex-football player turned software engineer. My fiancée and I got our dog Benji while completing our degrees, and since starting our careers have moved back to the San Diego area. We love taking Benji on long walks and watching football at local restaurants and breweries.",
                    },
                  },
                },
              ],
            },
            UnderlineColor: {
              S: "info",
            },
          },
        },
      ],
    },
  };

  return AWS.DynamoDB.Converter.unmarshall(data);
};
