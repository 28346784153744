import { createTheme, MuiThemeProvider } from "@material-ui/core";
import React, { useEffect } from "react";
import Icon from "../../Icon";
import "./Contact.css";
import { useSpring, animated, config } from "react-spring";
import ContactForm from "./ContactForm/ContactForm";
import Scroller from "../../Scroller";
import NavItemStatus from "../../NavBar/NavItemStatus";

const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 768, md: 900, lg: 1200, xl: 1536 } },
});

const GetPageSettings = (dbSettings) => {
  return {
    text: dbSettings.Content,
    textColor: dbSettings.Foreground,
    borderColor: dbSettings.HrColor,
    iconName: dbSettings.Icon,
    iconColor: dbSettings.IconColor,
    buttonColor: dbSettings.SubmitButtonColor,
    buttonText: dbSettings.SubmitButtonText,
  };
};

const GetIconAnimation = () => {
  return useSpring({
    from: { opacity: 0, x: -500 },
    to: { opacity: 1, x: 0 },
    config: config.gentle,
  });
};

const Contact = ({ dbSettings }) => {
  const pageSettings = GetPageSettings(dbSettings);

  Scroller.ScrollToTop();

  useEffect(() => {
    NavItemStatus.SetActiveLink("contactBtn");
  });

  return (
    <div className="pt-4 px-4 text-light mx-auto contactDiv">
      <MuiThemeProvider theme={theme}>
        <div
          id="contactPage"
          className={`text-${pageSettings.textColor} align-items-stretch justify-content-center`}
        >
          <div
            id="contactContent"
            className="flex-column justify-content-center col-xs-12 col-sm-5 mx-auto text-center contentTextSize"
          >
            <animated.div style={GetIconAnimation()}>
              <Icon
                iconName={pageSettings.iconName}
                color={pageSettings.iconColor}
                size="3em"
              />
            </animated.div>
            {pageSettings.text}
          </div>
          <div
            id="contactVr"
            className={`flex-column justify-content-center col-sm-1 vr bg-${pageSettings.borderColor} opacity-100 mx-5`}
          />
          <hr
            id="contactHr"
            className={`border border-1 border-${pageSettings.borderColor} opacity-100 my-3`}
          />
          <div
            id="contactForm"
            className="flex-column justify-content-center mx-auto col-xs-12 col-sm-5"
          >
            <ContactForm dbSettings={pageSettings} />
          </div>
        </div>
      </MuiThemeProvider>
    </div>
  );
};

export default Contact;
