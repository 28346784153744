import React, { Component } from "react";
import NavBar from "./components/NavBar/NavBar.jsx";
import Body from "./components/Body/Body";
import Footer from "./components/Footer/Footer";
import { fetchData } from "./AwsFunctions.js";
import "./App.css";
import * as AWS from "aws-sdk";
import { GetDefaultData } from "./DefaultData.js";

class App extends Component {
  async GetDataFromAws() {
    try {
      return await fetchData(
        "https://lw1tyg25lc.execute-api.us-west-1.amazonaws.com/Production/id/0"
      )
        .then((x) => x.json())
        .then((x) => {
          const unmarshalledData = AWS.DynamoDB.Converter.unmarshall(
            x.Items[0]
          );
          this.setState({ DbSettings: unmarshalledData });
        });
    } catch (error) {
      this.setState({ DbSettings: GetDefaultData() });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      DbSettings: [],
    };
  }

  componentDidMount() {
    this.GetDataFromAws();
  }

  render() {
    if (this.state.DbSettings.length == 0) {
      return <div className="mx-auto bg-black" />;
    }

    return (
      <div id="appDiv" className="mx-auto bg-black">
        <div id="navAndBody">
          <NavBar dbSettings={this.state.DbSettings} />
          <Body dbSettings={this.state.DbSettings} />
        </div>
        <Footer dbSettings={this.state.DbSettings} id="footer" />
      </div>
    );
  }
}

export default App;
