import { Route, Routes } from "react-router-dom";
import Home from "./Home/Home";
import Services from "./Services/Services";
import AboutMe from "./AboutMe/AboutMe";
import Contact from "./Contact/Contact";
import NotFound from "./NotFound/NotFound";
import Portfolio from "./Portfolio/Portfolio";

const Body = ({ dbSettings }) => {
  const homeSettings = dbSettings.Pages.filter(
    (page) => page.Name == "Home"
  )[0];

  const servicesSettings = dbSettings.Pages.filter(
    (page) => page.Name == "Services"
  )[0];

  const portfolioSettings = dbSettings.Pages.filter(
    (page) => page.Name == "Portfolio"
  )[0];

  const aboutSettings = dbSettings.Pages.filter(
    (page) => page.Name == "AboutMe"
  )[0];

  const contactSettings = dbSettings.Pages.filter(
    (page) => page.Name == "Contact"
  )[0];

  const notFoundSettings = dbSettings.Pages.filter(
    (page) => page.Name == "NotFound"
  )[0];

  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Home dbSettings={homeSettings} />} />
        <Route
          exact
          path="/services"
          element={<Services dbSettings={servicesSettings} />}
        />
        <Route
          exact
          path="/portfolio"
          element={<Portfolio dbSettings={portfolioSettings} />}
        />
        <Route
          exact
          path="/about-me"
          element={<AboutMe dbSettings={aboutSettings} />}
        />
        <Route
          exact
          path="/contact"
          element={<Contact dbSettings={contactSettings} />}
        />
        <Route
          exact
          path="*"
          element={<NotFound dbSettings={notFoundSettings} />}
        />
      </Routes>
    </div>
  );
};

export default Body;
