import React, { Component } from "react";
import "./ContactForm.css";
import AnimatedDiv from "../../../AnimatedDiv";
import { config } from "react-spring";
import EmailKey from "../../../../EmailKey.js";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      YourName: "",
      BusinessName: "",
      EmailAddress: "",
      Message: "",
    };
  }

  handleInputValueChanged = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  SubmitClicked = (e) => {
    "use strict";

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll(".needs-validation");

    // Loop over them and prevent submission
    Array.from(forms).forEach((form) => {
      if (!form.checkValidity()) {
        e.stopPropagation();
        e.preventDefault();
        form.classList.add("was-validated");
      } else {
        e.preventDefault();

        document.getElementById("spinner").classList.remove("d-none");

        var templateParams = {
          name: this.state.YourName,
          business_name: this.state.BusinessName,
          business_email: this.state.EmailAddress,
          message: this.state.Message,
        };

        try {
          emailjs
            .send(
              "default_service",
              EmailKey.TEMPLATE_ID,
              templateParams,
              EmailKey.USER_ID
            )
            .catch(() => {
              toast.error("Something went wrong, try again.");
              document.getElementById("spinner").classList.add("d-none");
            })
            .then((x) => {
              if (x.status == 200) {
                toast.success("Message Sent!");

                this.setState({
                  YourName: "",
                  BusinessName: "",
                  EmailAddress: "",
                  Message: "",
                });

                form.classList.remove("was-validated");
              } else {
                toast.error("Something went wrong, try again.");
              }

              document.getElementById("spinner").classList.add("d-none");
            });
        } catch (error) {
          toast.error("Something went wrong, try again.");
          document.getElementById("spinner").classList.add("d-none");
        }
      }
    });
  };

  render() {
    const children = [
      <div>
        <label htmlFor="nameFormInput" className="form-label">
          Your Name
        </label>
        <input
          required
          name="YourName"
          value={this.state.YourName}
          type="text"
          className="form-control bg-dark text-light"
          id="nameFormInput"
          placeholder="First &amp; Last Name"
          onChange={this.handleInputValueChanged}
        />
        <div className="invalid-feedback">Required</div>
      </div>,
      <div>
        <label htmlFor="businessNameFormInput" className="form-label">
          Business Name
        </label>
        <input
          name="BusinessName"
          value={this.state.BusinessName}
          type="text"
          className="form-control bg-dark text-light"
          id="businessNameFormInput"
          placeholder="(Optional)"
          onChange={this.handleInputValueChanged}
        />
      </div>,
      <div>
        <label htmlFor="emailFormInput" className="form-label">
          Email Address
        </label>
        <input
          required
          name="EmailAddress"
          value={this.state.EmailAddress}
          type="email"
          className="form-control bg-dark text-light"
          id="emailFormInput"
          placeholder="name@example.com"
          onChange={this.handleInputValueChanged}
        />
        <div className="invalid-feedback">Required</div>
      </div>,
      <div>
        <label htmlFor="messageFormInput" className="form-label">
          Message
        </label>
        <textarea
          required
          name="Message"
          value={this.state.Message}
          className="form-control bg-dark text-light"
          id="messageFormInput"
          rows="5"
          placeholder="What would you like to know?"
          onChange={this.handleInputValueChanged}
        />
        <div className="invalid-feedback">Required</div>
      </div>,
      <div>
        <button
          type="submit"
          id="submitBtn"
          onClick={this.SubmitClicked}
          className={`btn btn-${this.props.dbSettings.buttonColor}`}
        >
          {this.props.dbSettings.buttonText}
          <span
            id="spinner"
            className="spinner-border text-black spinner-border-sm ms-1 d-none"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>,
    ];

    return (
      <>
        <ToastContainer theme="dark" />
        <form
          id="myContactForm"
          className="contentTextSize needs-validation"
          noValidate
        >
          <div className="mb-3">
            <AnimatedDiv
              From={{ opacity: 0, y: -50 }}
              To={{ opacity: 1, y: 0 }}
              Config={config.gentle}
              Delay={350}
              Children={children[0]}
            />
          </div>
          <div className="mb-3">
            <AnimatedDiv
              From={{ opacity: 0, y: -50 }}
              To={{ opacity: 1, y: 0 }}
              Config={config.gentle}
              Delay={450}
              Children={children[1]}
            />
          </div>
          <div className="mb-3">
            <AnimatedDiv
              From={{ opacity: 0, y: -50 }}
              To={{ opacity: 1, y: 0 }}
              Config={config.gentle}
              Delay={550}
              Children={children[2]}
            />
          </div>
          <div className="mb-3">
            <AnimatedDiv
              From={{ opacity: 0, y: -50 }}
              To={{ opacity: 1, y: 0 }}
              Config={config.gentle}
              Delay={650}
              Children={children[3]}
            />
          </div>
          <div>
            <AnimatedDiv
              From={{ opacity: 0, y: -50 }}
              To={{ opacity: 1, y: 0 }}
              Config={config.gentle}
              Delay={750}
              Children={children[4]}
            />
          </div>
        </form>
      </>
    );
  }
}

export default ContactForm;
