import React from "react";
import Card from "../Card/Card";
import "./AnimatedCard.css";
import { useSpring, animated, config } from "react-spring";

const GetCardAnimation = (num) => {
  return useSpring({
    from: { opacity: 0, y: 50 },
    to: { opacity: 1, y: 0 },
    config: config.gentle,
    delay: num == 1 ? 750 : num == 2 ? 850 : num == 3 ? 950 : 1050,
  });
};

const AnimatedCard = ({ card }) => {
  const card1Animation = GetCardAnimation(1);
  const card2Animation = GetCardAnimation(2);
  const card3Animation = GetCardAnimation(3);
  const card4Animation = GetCardAnimation(4);

  return (
    <animated.div
      style={
        card.id == 0
          ? card1Animation
          : card.id == 1
          ? card2Animation
          : card.id == 2
          ? card3Animation
          : card4Animation
      }
      className="gridElement"
    >
      <Card card={card} />
    </animated.div>
  );
};

export default AnimatedCard;
