import React from "react";
import { useSpring, animated } from "react-spring";

const GetAnimation = (From, To, Config, Delay) => {
  return useSpring({
    from: From,
    to: To,
    config: Config,
    delay: Delay,
  });
};

const AnimatedDiv = ({ From, To, Config, Delay, Children }) => {
  return (
    <animated.div style={GetAnimation(From, To, Config, Delay)}>
      {Children}
    </animated.div>
  );
};

export default AnimatedDiv;
