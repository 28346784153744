import React from "react";
import Icon from "../../../Icon";
import { useSpring, animated, config } from "react-spring";
import "./Section.css";

const GetIconAnimation = (num) => {
  return useSpring({
    from: { opacity: 0, y: 25 },
    to: { opacity: 1, y: -20 },
    config: config.gentle,
    delay: num == 0 ? 250 : num == 1 ? 500 : 750,
  });
};

const Section = ({ section, alignIcon }) => {
  let desktopIcon = (
    <animated.div
      id="animatedIcon"
      style={GetIconAnimation(section.id)}
      className="text-center col-sm-6 col-xs-12"
    >
      <Icon
        iconName={section.icon}
        color={section.iconColor}
        size={"calc(20px + 10vw)"}
      />
    </animated.div>
  );

  let mobileIcon = (
    <animated.div
      id="mobileAnimatedIcon"
      style={GetIconAnimation(section.id)}
      className="text-center col-sm-6 col-xs-12 mx-auto mb-3"
    >
      <Icon
        iconName={section.icon}
        color={section.iconColor}
        size={"calc(50px + 10vw)"}
      />
    </animated.div>
  );

  let retval =
    alignIcon == "right" ? (
      <div
        className={`mx-auto content border border-2 rounded border-${section.borderColor} p-3 row align-items-center`}
      >
        <div className="headerAlignment col-sm-6 col-xs-12 mx-auto">
          <div className="d-inline-block">
            <span
              className={`headers text-${section.headerForeground}`}
              key={section.id}
            >
              {section.header}
            </span>
            <hr
              className={`border border-${section.borderColor} border-1 opacity-100`}
            />
          </div>
          {mobileIcon}
          {section.bulletPoints.map((bulletPoint) => (
            <div
              id="bulletPoint"
              className="mb-3 p-1 mx-auto"
              key={section.bulletPoints.indexOf(bulletPoint)}
            >
              <span
                className={`text-${section.textForeground} contentTextSize`}
              >
                {bulletPoint}
              </span>
            </div>
          ))}
        </div>
        {desktopIcon}
      </div>
    ) : (
      <div
        className={`mt-2 mx-auto content border border-2 rounded border-${section.borderColor} p-3 row align-items-center`}
      >
        {desktopIcon}
        <div className="headerAlignment col-sm-6 col-xs-12 mx-auto">
          <div className="d-inline-block">
            <span
              className={`headers text-${section.headerForeground}`}
              key={section.id}
            >
              {section.header}
            </span>
            <hr
              className={`border border-${section.borderColor} border-1 opacity-100`}
            />
          </div>
          {mobileIcon}
          {section.bulletPoints.map((bulletPoint) => (
            <div
              id="bulletPoint"
              className="mb-3 p-1 mx-auto"
              key={section.bulletPoints.indexOf(bulletPoint)}
            >
              <span
                className={`text-${section.textForeground} contentTextSize`}
              >
                {bulletPoint}
              </span>
            </div>
          ))}
        </div>
      </div>
    );

  return (
    <div
      style={{
        backgroundColor: section.sectionBackground,
      }}
      className={`${section.id == 0 ? "pt-3" : ""} pb-2 ms-4 me-4 mx-auto`}
      key={section.id}
    >
      <div className="pt-3 mx-auto">{retval}</div>
    </div>
  );
};

export default Section;
