export default class NavItemStatus {
  static linkIds = [
    "homeLink",
    "servicesLink",
    "portfolioLink",
    "aboutMeLink",
    "contactBtn",
  ];

  static SetActiveLink = (id) => {
    this.linkIds.forEach((linkId) => {
      document.getElementById(linkId).classList.remove("active");
    });

    document.getElementById(id).classList.add("active");
  };
}
